import React, { ChangeEvent } from 'react';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
// import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { Contact } from '../libs/objects';
import { setContact, getAllContacts } from '../libs/contact';

import { saveAs } from 'file-saver';


export type Props = {
    localEncryptionKey: Uint8Array;
}

export class State {
    importInProgress: boolean = false;
    importDone: boolean = false;
}

export default class ImportExportComponent extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = new State();
    }

    clickImport() {
        const input = document.querySelector("#app .importExportPage .import input") as HTMLInputElement;
        input.click();
    }
    async importSelected(e: ChangeEvent) {
        const elem = e.currentTarget as HTMLInputElement;
        if (!elem) return;
        if (!elem.files) return;
        const file = elem.files[0];
        if (!file) return;

        var reader = new FileReader();
        reader.onload = async (e) => {
            const listOfContactsAsAny = JSON.parse(reader.result as string) as any[];

            this.setState({ importInProgress: true });
            let n = 1;
            const finallyFn = () => {
                if (n >= listOfContactsAsAny.length) {
                    this.setState({ importInProgress: false, importDone: true });
                }
                n += 1;
            }
            for (const contact of listOfContactsAsAny) {
                const c = new Contact(contact.id);
                c.parse(contact.id, JSON.stringify(contact));
                await setContact(this.props.localEncryptionKey, c).catch((err) => {
                    console.log(err)
                }).finally(finallyFn);
            }
        }
        reader.readAsText(file);
    }

    async clickExport() {
        const allContacts = await getAllContacts(this.props.localEncryptionKey);
        const asJSON = JSON.stringify(allContacts, null, "    ")
        const b = new Blob([asJSON], { type: "application/json" });
        const date = new Date();
        saveAs(b, "addressBook_backup_"
            + date.getFullYear() + "-"
            + (date.getMonth() + 1) + "-"
            + date.getDate() + "-"
            + date.getHours() + ":"
            + date.getMinutes()
            + ".json");
    }

    render() {
        const importInProgress = (
            <MuiAlert variant="filled" severity="info">
                The import is in progress
            </MuiAlert>
        )
        const importDone = (
            <MuiAlert variant="filled" severity="success">
                Import done successfully
            </MuiAlert>
        )

        return (
            <div className="importExportPage">
                <Card className="import" variant="outlined">
                    <CardContent>
                        <Typography color="textSecondary" gutterBottom>
                            Import
                        </Typography>
                        {
                            this.state.importInProgress && importInProgress
                        }
                        {
                            this.state.importDone && importDone
                        }
                    </CardContent>
                    <CardActions>
                        <Button
                            size="large"
                            onClick={this.clickImport.bind(this)}
                        >
                            import file
                        </Button>
                    </CardActions>
                    <input hidden accept="application/json" type="file" onChange={this.importSelected.bind(this)} />
                </Card>

                <Card className="import" variant="outlined">
                    <CardContent>
                        <Typography color="textSecondary" gutterBottom>
                            Export
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button
                            size="large"
                            onClick={this.clickExport.bind(this)}
                        >
                            Get database backup
                        </Button>
                    </CardActions>
                </Card>
            </div>
        )
    }
}