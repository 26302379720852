import { encryptedLocalKeys_DB_ID, blockEncryptionKey_DB_ID } from "./init";

import localforage from "localforage";
import Axios from "axios";

const sessionStorageTokenID = "token";
const sessionStoragePublicKeyID = "pubKey";

export async function loadAndSaveToken(localEncryptionKey: Uint8Array): Promise<boolean> {
    let dur = "";
    const serverSecret = await fetch("/api/auth").then(async (resp) => {
        if (resp.body) {
            const reader = resp.body.getReader();
            const buf = await reader.read();
            dur = resp.headers.get("dur") as string;

            return buf.value
        };
    });

    const encryptedKeys = await localforage.getItem(encryptedLocalKeys_DB_ID).then((keys) => {
        if (!keys) {
            throw new Error("no key");
        }
        return keys as Uint8Array;
    })

    const token = await import("../wasm/index").then((wasm) => {
        return wasm.build_token(localEncryptionKey, encryptedKeys, serverSecret);
    });

    sessionStorage.setItem(sessionStorageTokenID, token);
    Axios.defaults.headers.common['Authorization'] = token;

    if (dur === "") {
        console.error("no duration given");
        return false;
    }

    const durAsInt = parseInt(dur);
    setTimeout(() => {
        loadAndSaveToken(localEncryptionKey)
    }, durAsInt);


    return true;
}

export async function getClearBlockKey(localEncryptionKey: Uint8Array): Promise<Uint8Array> {
    const encryptedBlockKey = await localforage.getItem(blockEncryptionKey_DB_ID).then((encryptedBlockKey) => {
        if (!encryptedBlockKey) {
            throw new Error("no encryptedBlockKey");
        }
        return encryptedBlockKey as Uint8Array;
    });

    const blockKeyCleared = await import("../wasm/index").then((wasm) => {
        return wasm.decrypt_content_symmetric(localEncryptionKey, encryptedBlockKey) as Uint8Array;
    });

    return blockKeyCleared;
}

export async function setEncryptedBlockKey(localEncryptionKey: Uint8Array, clearBlockKey: Uint8Array): Promise<boolean> {
    const blockKeyEncrypted = await import("../wasm/index").then((wasm) => {
        return wasm.encrypt_content_symmetric(localEncryptionKey, clearBlockKey) as Uint8Array;
    });

    await localforage.setItem(blockEncryptionKey_DB_ID, blockKeyEncrypted).then((encryptedBlockKey) => {
        if (!encryptedBlockKey) {
            throw new Error("no encryptedBlockKey");
        }
        return encryptedBlockKey as Uint8Array;
    });

    return true;
}

export async function getPublicKeyString(encryptionKey: Uint8Array): Promise<string> {
    const encryptedKeys = await localforage.getItem(encryptedLocalKeys_DB_ID).then((keys) => {
        if (!keys) {
            throw new Error("no key");
        }
        return keys as Uint8Array;
    })

    const publicKeyAsString = await import("../wasm/index").then((wasm) => {
        return wasm.get_clear_public_key_as_string(encryptionKey, encryptedKeys);
    });

    return publicKeyAsString;
}

export function getToken(): string {
    const token = sessionStorage.getItem(sessionStorageTokenID);
    if (token) {
        return token;
    }
    return "";
}

export function setPublicKey(input: string) {
    sessionStorage.setItem(sessionStoragePublicKeyID, input);
}

export function getPublicKey(): string {
    const pub = sessionStorage.getItem(sessionStoragePublicKeyID);

    if (pub) {
        return pub;
    }
    return "";
}