import React, { FormEvent } from 'react';
import ReactDOM from 'react-dom';

import { getPublicKeyString, getClearBlockKey } from "../libs/auth";

import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';


import ImageIcon from '@material-ui/icons/Image';
import FaceIcon from '@material-ui/icons/Face';
import DeleteIcon from '@material-ui/icons/Delete';

import { Device } from "../libs/objects";
// import { loadAndSaveToken } from "../libs/auth";

import Axios from 'axios';

export type Props = {
    localEncryptionKey: Uint8Array;
}

export class States {
    devices: Device[] = [];
    localPublicKey: string = "";
    requestError: boolean = false;
    showRequestResponse: boolean = false;
    requestResponse: string = "";
}

export default class DevicesComponent extends React.Component<Props, States> {
    constructor(props: Props) {
        super(props);


        getPublicKeyString(this.props.localEncryptionKey).then((pubAsString) => {
            this.setState({ localPublicKey: pubAsString });
        })

        this.updateDevices();

        this.state = new States();
    }

    updateDevices() {
        Axios.get("/api/devices").then((resp) => {
            const devices: Device[] = resp.data;

            this.setState({ devices });
        })
    }

    async addDeviceSubmit(e: FormEvent) {
        e.preventDefault();
        this.addDevice();
    }
    async addDevice() {
        // const setState = this.setState;
        const node = ReactDOM.findDOMNode(this);
        const errorFn = () => { this.setState({ showRequestResponse: false, requestError: true }); };

        // Get child nodes
        if (node instanceof HTMLElement) {
            const input = node.querySelector('#addDeviceArea input') as HTMLInputElement;

            try {
                const request: Device = JSON.parse(input.value);
                if (request) {
                    const comp = this;
                    // const localEncryptionKey = this.props.localEncryptionKey;
                    // const sendTheNewDeviceToServer = this.sendTheNewDeviceToServer;

                    const ok = await import("../wasm/index").then(async (mod) => {
                        const keys = mod.build_ecdh_key().split(",");
                        const secret = keys[0];
                        const publicKey = keys[1];

                        const clearBlockKey = await getClearBlockKey(comp.props.localEncryptionKey) as Uint8Array;
                        const encryptedStoreKey = mod.encrypt_content_for_public_key(
                            secret,
                            request.ecdh_public_key_base64,
                            clearBlockKey,
                        );

                        const encryptedBlocksKey = publicKey + "," + encryptedStoreKey;
                        comp.sendTheNewDeviceToServer(request);
                        return { ok: true, encryptedBlocksKey: encryptedBlocksKey }
                    }).catch((e) => {
                        errorFn();
                        console.error("the token is not valid", e);
                        return { ok: false, encryptedBlocksKey: "" };
                    });

                    if (ok.ok) {
                        this.setState({ showRequestResponse: true, requestError: false, requestResponse: ok.encryptedBlocksKey });
                    }

                } else {
                    errorFn();
                    console.error("request is not valid");
                }
            } catch (_) {
                if (input.value === "") {
                    this.setState({ showRequestResponse: false, requestError: false });
                } else {
                    errorFn();
                }
            }

            console.info("send value to the server and display the encrypted key");
        }
    }

    sendTheNewDeviceToServer(newDevice: Device) {
        const comp = this;
        Axios.post("/api/devices", newDevice).then(() => {
            comp.updateDevices();
        })
    }

    delete(pubKeyToRM: string) {
        Axios.delete("/api/devices", {
            params: {
                pub_key: pubKeyToRM
            }
        }).then((resp) => {
            const newDevices = resp.data as [Device];
            this.setState({ devices: newDevices })
            this.updateDevices();
        })
    }

    render() {
        this.addDeviceSubmit.bind(this);

        const request = (
            <form id="request" onSubmit={(e: FormEvent) => { this.addDeviceSubmit(e) }}>
                <Typography variant="h5" component="h2" >
                    Add a new device
                </Typography >
                <TextField
                    autoFocus
                    error={this.state.requestError}
                    label="Remote device token"
                />
                <Button variant="outlined" onClick={this.addDevice.bind(this)}>
                    add
                </Button>
            </form>
        );

        const answer = (
            <div id="answerArea">
                <Typography component="h1" variant="h5">
                    Return this to the requesting device
                </Typography >
                <Typography component="p" className="answer" >
                    {this.state.requestResponse}
                </Typography >
            </div>
        );

        const devices = this.state.devices.map((device) =>
            <DeviceComponent
                deleteDevice={this.delete.bind(this)}
                key={device.public_key_base64.toString()}
                local={device.public_key_base64 === this.state.localPublicKey}
                device={device} />
        );


        return (
            <div id="devicePage" >
                <Typography variant="h4" component="h1">
                    Manage the devices
                </Typography>
                <Paper id="addDeviceArea">
                    {this.state.showRequestResponse ? answer : request}
                </Paper>
                <List component="nav" aria-label="Devices">
                    {devices}
                </List>
            </div>
        )
    }
}

export type DeviceProps = {
    // key: string;
    device: Device;
    local: boolean;
    deleteDevice: (pubKey: string) => void;
}

export type DeviceStates = {

}

export class DeviceComponent extends React.Component<DeviceProps, DeviceStates> {
    render() {
        const secondaryAction = (
            <ListItemSecondaryAction>
                <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => { this.props.deleteDevice(this.props.device.public_key_base64) }}
                >
                    <DeleteIcon />
                </IconButton>
            </ListItemSecondaryAction>
        );

        return (
            <ListItem>
                <ListItemAvatar>
                    <Avatar>
                        {this.props.local ?
                            <FaceIcon /> :
                            <ImageIcon />
                        }
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={this.props.device.name}
                    secondary={this.props.device.public_key_base64} />
                {!this.props.local && secondaryAction}
            </ListItem>
        )
    }
}