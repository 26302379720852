import React, { FormEvent } from 'react';
import ReactDOM from 'react-dom';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import localforage from "localforage";

import { LocalDevice_DB_ID, Device } from "../libs/objects";
import { setEncryptedBlockKey } from "../libs/auth";

export type Props = {
    moveToPage: (page: string) => void;
    localEncryptionKey: Uint8Array;
}

export class State {
    token: string = "";
    tokenError: boolean = false;
}


export default class AskForAuth extends React.Component<Props, State> {
    secretKey = "";
    publicKey = "";

    constructor(props: Props) {
        super(props);

        localforage.getItem(LocalDevice_DB_ID).then((device) => {
            import("../wasm").then((mod) => {
                const keysASBase64 = mod.build_ecdh_key() as string;
                const keys = keysASBase64.split(",");
                const secret = keys[0];
                const pub = keys[1];

                this.secretKey = secret;
                this.publicKey = pub;

                let token = device as Device;
                token.ecdh_public_key_base64 = this.publicKey;

                const deviceAsString = JSON.stringify(token);

                this.setState({ token: deviceAsString });
            });

        })


        this.state = new State();
    }

    async saveSubmit(e: FormEvent) {
        e.preventDefault();
        this.save();
    }
    save() {
        const node = ReactDOM.findDOMNode(this);

        // Get child nodes
        if (node instanceof HTMLElement) {
            const inputElem = node.querySelector('#response') as HTMLInputElement;

            const pubKeyAndKey = inputElem.value.split(",");
            const pubKey = pubKeyAndKey[0];
            const encryptedKey = pubKeyAndKey[1];

            import("../wasm").then(async (mod) => {
                const blockEncryptionKey = mod.decrypt_content_with_private_key(this.secretKey, pubKey, encryptedKey);

                await setEncryptedBlockKey(this.props.localEncryptionKey, blockEncryptionKey);

                this.props.moveToPage("devices");
                // loadAndSaveToken(blockEncryptionKey).finally(() => {
                // })
            }).catch(() => {
                this.setState({ tokenError: true })
            })
        }
    }

    render() {
        this.saveSubmit.bind(this);

        return (
            <Paper className="needToBeAuth">

                <Typography variant="h5" component="h3">
                    This server is already configured
                </Typography>
                <Typography component="p">
                    The application did not found you public key on the server.
                </Typography>
                <Typography component="p">
                    An authenticated user need to add the current device using this invitation request.
                </Typography>

                <Typography component="p" className="invitationRequest">
                    {this.state.token}
                </Typography >

                <form onSubmit={(e) => this.saveSubmit(e)}>
                    <TextField
                        fullWidth
                        error={this.state.tokenError}
                        id="response"
                        label="Enter the response inviter"
                        variant="outlined"
                    />
                    <Button variant="outlined" color="primary" fullWidth onClick={this.save.bind(this)}>
                        save
                    </Button>
                </form>
            </Paper>
        )
    }
}