import React from 'react';

import Button from '@material-ui/core/Button';

export type Props = {
    moveToPage: (page: string) => void;
}

export class State { }

export default class SettingsComponent extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = new State();
    }

    render() {
        return (
            <div className="settingPage">
                <Button variant="outlined" size="large" color="primary" onClick={this.props.moveToPage.bind(this, "devices")}>
                    devices
                </Button>
                <Button variant="outlined" size="large" color="primary" onClick={this.props.moveToPage.bind(this, "importExport")}>
                    import / export
                </Button>
                {/* <Button variant="outlined" size="large" color="primary" onClick={this.props.moveToPage.bind(this, "pasword")}>
                    update password
                </Button> */}
            </div>
        )
    }
}