import localStorage from "localforage";
import Axios from "axios";

import { blockToContact, decryptContact, getTmpStorage, deleteContactLocally, setContactToServer, setContactLocally } from "./contact";
import { Contact } from "./objects";
import { getClearBlockKey } from "./auth";

// import { getFirstCongetLastLocallySavedBlocktact } from "../libs/contact";

const lastBlock_DB_ID = "lastBlock";

export class Block {
    rank: number = 0;
    hash: string = "";

    constructor(rank: number, hash: string) {
        this.rank = rank;
        this.hash = hash;
    }

    static fromBytes(input: Uint8Array): Block {
        const decoder = new TextDecoder();
        const jsonRepresentation = decoder.decode(input);
        const parsed = JSON.parse(jsonRepresentation);

        return new Block(parsed.rank, parsed.hash);
    }
    static fromAny(input: any): Block {
        return new Block(input.rank, input.hash);
    }

    public toBytes(): Uint8Array {
        const jsonRepresentation = JSON.stringify(this);
        const encoder = new TextEncoder();
        return encoder.encode(jsonRepresentation);
    }
}

class BlockPresentedByServer {
    rank: number = 0;
    hash: Uint8Array = new Uint8Array();
    content: Uint8Array = new Uint8Array();

    async hashString(): Promise<string> {
        return await import("../wasm/index").then((wasm) => {
            return wasm.to_base64(this.hash);
        });
    }

    async toLocalBlock(): Promise<Block> {
        const hash = await this.hashString();

        return new Block(this.rank, hash);
    }

    static fromAny(input: any): BlockPresentedByServer {
        let ret = new BlockPresentedByServer();
        ret.rank = input.rank;
        ret.hash = input.hash;
        ret.content = input.content;
        return ret;
    }
}

export async function getLastLocallySavedBlock(localEncryptionKey: Uint8Array): Promise<Block> {
    return localStorage.getItem(lastBlock_DB_ID).then(async (encryptedLastBlock) => {
        if (!encryptedLastBlock) {
            throw new Error("No saved block");
        }

        const lastBlock = await import("../wasm/index").then((wasm) => {
            return wasm.decrypt_content_symmetric(localEncryptionKey, encryptedLastBlock);
        });
        return Block.fromBytes(lastBlock);
    });
}

export async function setLastSavedBlock(localEncryptionKey: Uint8Array, block: Block): Promise<void> {
    const encryptedBlock = await import("../wasm/index").then((wasm) => {
        return wasm.encrypt_content_symmetric(localEncryptionKey, block.toBytes());
    });

    console.info("new local rank", block.rank);

    await localStorage.setItem(lastBlock_DB_ID, encryptedBlock);
}

export async function startBlockListener(localEncryptionKey: Uint8Array, updated: () => void, onlined: (status: boolean) => void): Promise<number> {
    // Run the upload of local modification if any
    sendSavedChanges(localEncryptionKey);

    window.addEventListener('online', async () => {
        console.info("back online");
        onlined(true);
        sendSavedChanges(localEncryptionKey);
    });
    window.addEventListener('offline', async () => {
        console.info("offline");
        onlined(false);
    });

    // const updateFrequency = 500; // every half second it ask if the block is up to date
    const updateFrequency = 2000; // every 2 seconds it ask if the block is up to date
    // const updateFrequency = 1000 * 5; // every 5 seconds it ask if the block is up to date

    return window.setInterval(async () => {
        if (!navigator.onLine) {
            return;
        }

        // get the latest local block
        const lastSavedBlock = await getLastLocallySavedBlock(localEncryptionKey)
            .then(async (ret) => {
                return ret;
            })
            .catch(async () => {
                return new Block(0, "");
            });


        // Get the last remote block
        let headers = {
            "Last": lastSavedBlock.rank.toString()
        };
        await Axios.head("/api/blocks", {
            headers,
        }).then(async () => {
            console.info("need to update local state");

            await upgrade(localEncryptionKey)
                .then(updated)
                .catch(() => { });
        }).catch((err) => {
            const resp = err.response;
            if (resp.status === 304) {
                console.debug("up to date no thing to do");
                return;
            }
            console.error(err);
        });
    }, updateFrequency)
}

async function sendSavedChanges(localEncryptionKey: Uint8Array) {
    const blockKey = await getClearBlockKey(localEncryptionKey);

    const storage = getTmpStorage();
    let tmpIdsToRemove: string[] = [];

    // List every local modifications
    await storage.iterate(async (value, key, iterationNumber) => {
        // Rebuild the contact
        const decryptedContactAsBytes = await decryptContact(blockKey, value as Uint8Array);
        let contact = new Contact(key);
        contact.parseFromUint8Array("", decryptedContactAsBytes);

        // Save the contact on the server
        await setContactToServer(localEncryptionKey, contact).then(() => {
            // If OK the the tmp value is removed
            tmpIdsToRemove.push(contact.id);
        }).catch((e) => {
            console.error("can't send modificatation to server", e)
        });
        // }).then(() => {
    }).catch((err) => {
        // This code runs if there were any errors
        console.error(err);
    }).finally(async () => {
        for (const id of tmpIdsToRemove) {
            await storage.removeItem(id);
        }
    });
}

// if start and howMany are equal to 0 it returns the last block
export async function getRemoteBlocks(start: number, howMany: number): Promise<BlockPresentedByServer[]> {
    let headers = {}
    if (start !== 0 || howMany !== 0) {
        headers = {
            "Start": start.toString(),
            "Nb": howMany.toString()
        };
    }

    const rawBlocks = await Axios.get("/api/blocks", {
        responseType: 'arraybuffer',
        headers,
    }).then(async (rep) => {
        const bytes = new Uint8Array(rep.data);
        return await import("../wasm/index").then((wasm) => {
            return wasm.parse_blocks(bytes);
        });
    }).catch((e) => {
        if (e.response.status !== 404) {
            alert("Error with \"libs/blocks.getRemoteBlocks\". Contact the administrator")
        }
        throw e;
    })

    let blocks: BlockPresentedByServer[] = [];
    blocks.length = rawBlocks.length;
    let i = 0;
    for (const block of rawBlocks) {
        blocks[i] = BlockPresentedByServer.fromAny(block);
        i++;
    };

    return blocks;
}

// upgrade loads and save every block "from" to the end and returns the number of blocks pared.
async function upgrade(localEncryptionKey: Uint8Array): Promise<void> {
    // Prevent running the sync multiple times
    if (window.upgradeOngoing) {
        const s = "Upgrade ongoing stop this request";
        console.info(s);
        throw new Error(s);
    };
    window.upgradeOngoing = true;

    const nbToLoad = 100;

    // The try is to prevent any stalled lock with window.upgradeOngoing
    try {
        let from = (await getLastLocallySavedBlock(localEncryptionKey).then((lb) => {
            return lb.rank + 1;
        }).catch(() => { return 0 }));
        while (true) {
            console.info("start upgrade loop from:", from);

            const loadedBlocks = await getRemoteBlocks(from, nbToLoad)
                .then(async (blocks) => {
                    return blocks;
                });

            const contacts = await blocksFromServerToContacts(localEncryptionKey, loadedBlocks);

            await saveContacts(localEncryptionKey, loadedBlocks, contacts);

            if (loadedBlocks.length <= nbToLoad) {
                const lastRemoteBlock = (await getRemoteBlocks(0, 0))[0];
                const lastLoaded = loadedBlocks[loadedBlocks.length - 1];

                if (await lastRemoteBlock.hashString() === await lastLoaded.hashString()) {
                    console.info("the upgrade completed");
                    break;
                }

                from = lastLoaded.rank + 1;
            }
        }
    } catch (e) {
        // statements to handle any exceptions
        console.error(e); // pass exception object to error handler
    }

    window.upgradeOngoing = false;
}

async function blocksFromServerToContacts(localEncryptionKey: Uint8Array, blocksFromServer: BlockPresentedByServer[]): Promise<Contact[]> {
    let ret: Contact[] = [];
    ret.length = blocksFromServer.length;

    let i = 0;
    for (const block of blocksFromServer) {
        const contact = await blockToContact(localEncryptionKey, block.content);
        ret[i] = contact;
        i++;
    }

    return ret;
}

async function saveContacts(localEncryptionKey: Uint8Array, blocks: BlockPresentedByServer[], newContacts: Contact[]): Promise<void> {
    let i = 0;
    for (const contact of newContacts) {
        if (!contact.toDelete) {
            await setContactLocally(localEncryptionKey, await blocks[i].toLocalBlock(), contact);
        } else {
            await deleteContactLocally(localEncryptionKey, contact.id, await blocks[i].toLocalBlock());
        }
        i++;
    }
}