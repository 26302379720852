import { PDFDocument, degrees, StandardFonts } from 'pdf-lib';
import { saveAs } from 'file-saver';

export async function printDL(): Promise<void> {
    const imageBytes = await fetch("/print/DLImage.png").then((res) => res.arrayBuffer())

    const pdfDoc = await PDFDocument.create()
    const image = await pdfDoc.embedPng(imageBytes)
    // Add page DL
    const page = pdfDoc.addPage([311.79, 623.58])

    // Set the logo up side down at the bottom of the page
    const prop = {
        x: page.getWidth() - 15,
        y: 115,
        width: page.getWidth() - 30,
        height: 100,
        rotate: degrees(180)
    }
    page.drawImage(image, prop)

    // Load font
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const helveticaBoldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
    page.setFont(helveticaFont);
    page.moveTo(150, 240);
    page.drawText(
        window.contact.company,
        {
            size: 15,
            lineHeight: 15,
            rotate: degrees(90),
            font: helveticaBoldFont
        }
    )
    page.moveTo(170, 240);
    page.drawText(
        window.contact.address.contact + "\n" +
        window.contact.address.street + "\n" +
        window.contact.address.zip + " " +
        window.contact.address.city + "\n" +
        window.contact.address.country,
        {
            size: 13,
            lineHeight: 15,
            rotate: degrees(90)
        }
    )

    const pdfBytes = await pdfDoc.save()

    saveFile(pdfBytes, "DL.pdf")
}

export async function printSticker(): Promise<void> {
    const imageBytes = await fetch("/print/DLImage.png").then((res) => res.arrayBuffer())

    const pdfDoc = await PDFDocument.create()
    const image = await pdfDoc.embedPng(imageBytes)
    // Add page sticker 105mm*148mm
    const page = pdfDoc.addPage([297.62, 419.50])

    // Set the logo up side down at the bottom of the page
    const prop = {
        x: page.getWidth() - 15,
        y: 115,
        width: page.getWidth() - 30,
        height: 100,
        rotate: degrees(180)
    }
    page.drawImage(image, prop)

    // Load font
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const helveticaBoldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
    page.setFont(helveticaFont);
    page.moveTo(150, 140);
    page.drawText(
        window.contact.company,
        {
            size: 15,
            lineHeight: 15,
            rotate: degrees(90),
            font: helveticaBoldFont
        }
    )
    page.moveTo(170, 140);
    page.drawText(
        window.contact.address.contact + "\n" +
        window.contact.address.street + "\n" +
        window.contact.address.zip + " " +
        window.contact.address.city + "\n" +
        window.contact.address.country,
        {
            size: 13,
            lineHeight: 15,
            rotate: degrees(90)
        }
    )

    const pdfBytes = await pdfDoc.save()

    saveFile(pdfBytes, "sticker.pdf")
}

function saveFile(content: Uint8Array, title: String) {
    const b = new Blob([content], { type: "application/pdf" });
    saveAs(b, title.toString());
}