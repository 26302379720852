import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { printDL, printSticker } from "../libs/prints";

export type Props = {
    moveToPage: (page: string) => void;
    isContact: boolean;
    // isSettings: boolean;
}

export class State {
    printButtonOpen = false;
}

export default class AppBarComponent extends React.Component<Props, State> {
    printButton: React.RefObject<HTMLButtonElement>;
    printDLImageElem: React.RefObject<HTMLImageElement>;
    constructor(props: Props) {
        super(props);

        this.printButton = React.createRef();
        this.printDLImageElem = React.createRef();
        this.state = new State();
    }

    openPrintMenu(event: React.MouseEvent<HTMLButtonElement>) {
        this.setState({ printButtonOpen: true });
    }
    closedPrintMenu() {
        this.setState({ printButtonOpen: false });
    }
    async printDL() {
        await printDL();
        this.closedPrintMenu();
    }
    async printSticker() {
        await printSticker();
        this.closedPrintMenu();
    }

    render() {
        return (
            <AppBar className="appBar" position="static">
                <Toolbar variant="dense" className="buttons">
                    <Button variant="outlined" size="small" onClick={this.props.moveToPage.bind(this, "contact")}>
                        contacts
                    </Button>
                    {this.props.isContact &&
                        <Button variant="outlined" size="small" onClick={this.openPrintMenu.bind(this)} ref={this.printButton}>
                            print
                        </Button>
                    }
                    <Button variant="outlined" size="small" onClick={this.props.moveToPage.bind(this, "settings")}>
                        settings
                    </Button>
                </Toolbar>

                <Menu open={this.state.printButtonOpen} onClose={this.closedPrintMenu.bind(this)} anchorEl={this.printButton.current}>
                    <MenuItem onClick={this.printDL.bind(this)}>Envelope DL</MenuItem>
                    <MenuItem onClick={this.printSticker.bind(this)}>Sticker</MenuItem>
                </Menu>
            </AppBar>
        )
    }
}