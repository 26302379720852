import React from 'react';

import { Phone } from '../libs/objects';

import { DeleteDialog } from "./dialogs";

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import ContactPhone from '@material-ui/icons/ContactPhone';
import PhoneIcon from '@material-ui/icons/Phone';

export class PhonesComponentProps {
    phones: Phone[] = [];
    updated: (phones: Phone[]) => void = (_) => { return };
}

export class PhonesComponentState {
    phones: Phone[] = [];
}

export class PhonesComponent extends React.Component<PhonesComponentProps, PhonesComponentState> {
    constructor(props: PhonesComponentProps) {
        super(props);
        this.state = { phones: props.phones };
    }

    static getDerivedStateFromProps(nextProps: PhonesComponentProps, _: PhonesComponentState): PhonesComponentState {
        return { phones: nextProps.phones };
    }

    updatePhones(phones: Phone[]) {
        this.setState({ phones: phones });
        this.props.updated(phones);
    }
    updatePhone(phone: Phone) {
        // this.checkStateUpdate();
        let phones = this.state.phones;
        phones[phone.order.valueOf()] = phone;
        this.setState({ phones: phones });
        this.props.updated(phones);
    }
    // checkStateUpdate(phones: Phone[]) {
    //     if (this.state.phones.length === 0 && this.state.phones.length > 0) {
    //         this.setState({phones: phones})
    //     }
    // }

    addNumber() {
        // this.checkStateUpdate();
        const newNum = new Phone();
        newNum.lib = "";
        newNum.num = "";
        newNum.order = this.state.phones.length;

        let phones = this.state.phones;
        phones.push(newNum);

        this.setState({ phones: phones })
        this.props.updated(phones);
    }

    deleteNumber(phone: Phone) {
        let newList: Phone[] = [];

        for (const existingNumber of this.state.phones) {
            if (existingNumber.id === phone.id) {
                continue
            }

            if (existingNumber.order > phone.order) {
                existingNumber.order = (existingNumber.order as number) - 1;
            }

            newList.push(existingNumber);
        }

        this.updatePhones(newList);
    }

    render() {
        const listPhones = this.state.phones.map((phone) =>
            // Correct! Key should be specified inside the array.
            <PhoneComponent
                key={phone.id}
                phone={phone}
                updated={this.updatePhone.bind(this)}
                deleted={this.deleteNumber.bind(this)}
            />
        );

        return (
            <div className="phonesList">
                <div className="titleButtonGroup">
                    <Typography variant="h6">Phone numbers</Typography>
                    <Button variant="outlined" color="primary" className="add"
                        onClick={this.addNumber.bind(this)} size="small">
                        Add phone number
                    </Button>
                </div>
                {listPhones}
            </div>
        )
    }
}

export type PhoneComponentProps = {
    phone: Phone;
    key: string;
    updated: (phone: Phone) => void;
    deleted: (phone: Phone) => void;
}

export class PhoneComponentStates extends Phone {
    delDialogOpen: boolean = false;
}

export class PhoneComponent extends React.Component<PhoneComponentProps, PhoneComponentStates> {
    // phone: Phone;

    constructor(props: PhoneComponentProps) {
        super(props);

        const state = this.props.phone as PhoneComponentStates;
        state.delDialogOpen = false;

        this.state = state;
    }

    static getDerivedStateFromProps(nextProps: PhoneComponentProps, states: PhoneComponentStates): PhoneComponentStates {
        let ret = nextProps.phone as PhoneComponentStates;
        ret.delDialogOpen = states.delDialogOpen;
        return ret;
    }

    // static getDerivedStateFromProps(nextProps: PhoneComponentProps, _: PhoneComponentStates): PhoneComponentStates {
    //     const state = nextProps.phone as PhoneComponentStates;
    //     state.delDialogOpen = false;
    //     return state;
    // }

    callNumber(phone: Phone) {
        window.open("tel:" + phone.num.replace(/\W/g, ""), "Call " + phone.lib);
    }

    // Delete
    askToDeleteNumber() {
        this.setState({ delDialogOpen: true } as PhoneComponentStates);
    }
    onClose(_: React.MouseEvent) {
        this.setState({ delDialogOpen: false } as PhoneComponentStates);
    }
    onConfirm(_: React.MouseEvent, phone: any) {
        console.debug("delete the phone: ", phone);
        this.props.deleted(phone);
    }

    // Edit fields handling
    onLibChange(event: React.FormEvent) {
        const val = this.getValFromEvent(event);
        let phone = {} as PhoneComponentStates;
        Object.assign(phone, this.state);
        phone.lib = val;
        this.updated(phone);
    }
    onNumChange(event: React.FormEvent) {
        const val = this.getValFromEvent(event);
        let phone = {} as PhoneComponentStates;
        Object.assign(phone, this.state);
        phone.num = val;
        this.updated(phone);
    }
    getValFromEvent(e: React.FormEvent): string {
        const input = e.currentTarget as HTMLInputElement;
        return input.value;
    }
    updated(phone: Phone) {
        this.setState(phone);
        this.props.updated(phone);
    }

    render() {
        return (
            <Paper className="phoneLine" id={this.state.id} style={({
                order: this.state.order.valueOf()
            })}>
                <div className="order">{this.state.order}</div>
                <div className="lib">
                    <FormControl fullWidth variant="outlined" className="line">
                        <InputLabel htmlFor="outlined-adornment-amount">Label</InputLabel>
                        <OutlinedInput
                            margin="dense"
                            placeholder="New phone label"
                            onChange={this.onLibChange.bind(this)}
                            value={this.state.lib}
                            startAdornment={<InputAdornment position="start"><ContactPhone /></InputAdornment>}
                            labelWidth={40}
                        />
                    </FormControl>
                </div>
                <div className="num">
                    <FormControl fullWidth variant="outlined" className="line">
                        <InputLabel htmlFor="outlined-adornment-amount">Number</InputLabel>
                        <OutlinedInput
                            margin="dense"
                            placeholder="+33 1 00 00 00 00"
                            onChange={this.onNumChange.bind(this)}
                            value={this.state.num}
                            startAdornment={<InputAdornment position="start"><PhoneIcon /></InputAdornment>}
                            labelWidth={60}
                        />
                    </FormControl>
                </div>
                <ButtonGroup
                    // color="secondary"
                    size="small"
                    aria-label="small outlined secondary button group"
                >
                    <Button className="call" color="primary"
                        onClick={this.callNumber.bind(this, this.state)}>
                        Call
                    </Button>
                    <Button className="del" color="secondary"
                        onClick={this.askToDeleteNumber.bind(this, this.state)}>
                        Delete
                    </Button>
                </ButtonGroup>
                {this.state.delDialogOpen &&
                    <DeleteDialog
                        lib={this.state.lib}
                        elem={this.state as any}
                        onClose={this.onClose.bind(this)}
                        onConfirm={this.onConfirm.bind(this)}></DeleteDialog>
                }
            </Paper>
        )
    }
}