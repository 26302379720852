
import React from 'react';

import './App.scss';

import { Contact } from "./libs/objects";
import { ThemeProvider } from '@material-ui/styles';
import { theme } from './libs/theme';

import ContactComponent from "./components/contact";
import LoginComponent from "./components/login";
import DevicesComponent from "./components/devices";
import AppBarComponent from "./components/appBar";
import AskForAuthComponent from "./components/askForAuth";
import SettingsComponent from "./components/settings";
import ImportExportComponent from "./components/importExport";

import('./wasm/index').then(m => {
  m.init_panic_hook();

  window.testPassDerivation = (s: number, c: number) => {
    const t1 = new Date();

    m.build_encryption_key_from_string_password(
      "hello pass",
      new Uint8Array(16),
      s,
      c
    )

    const t2 = new Date();
    console.log(t2.getTime() - t1.getTime());
  }
});

declare global {
  interface Window {
    upgradeOngoing: boolean;
    contact: Contact;
    testPassDerivation: any;
  }
}

export type AppComponentProps = {}

export type AppComponentState = {
  search: string;
  // contact: Contact;

  pages: Pages;

  localEncryptionKey: Uint8Array;
  // remoteEncryptionKey: Uint8Array;
}

class Pages {
  login = false;
  devices = false;
  contact = false;
  askAuth = false;
  settings = false;
  importExport = false;
}


export class App extends React.Component<AppComponentProps, AppComponentState> {
  localEncryptionKey: Uint8Array;
  pages = new Pages();

  constructor(params: AppComponentProps) {
    super(params);

    this.localEncryptionKey = new Uint8Array(0);

    this.pages.login = true;

    this.state = {
      search: "",
      // contact: new Contact("", getPublicKey()),

      pages: this.pages,

      localEncryptionKey: this.localEncryptionKey,
    };
  }

  // async setFirstContact() {
  //   const contact = await getFirstContact();
  //   // this.setState({ contact });
  // }

  async setLocalEncryptionKey(localEncryptionKey: Uint8Array): Promise<void> {
    this.localEncryptionKey = localEncryptionKey;
    this.setState({ localEncryptionKey: this.localEncryptionKey });
  }

  moveToPage(pageStr: string) {
    let allPages = new Pages();

    if (pageStr === "login") {
      allPages.login = true;
    } else if (pageStr === "devices") {
      allPages.devices = true;
    } else if (pageStr === "contact") {
      allPages.contact = true;
    } else if (pageStr === "askAuth") {
      allPages.askAuth = true;
    } else if (pageStr === "settings") {
      allPages.settings = true;
    } else if (pageStr === "importExport") {
      allPages.importExport = true;
    } else {
      console.error("asked page is not implemented: " + pageStr);
      return;
    }

    console.info("move to page: " + pageStr);
    this.setState({ pages: allPages });
  }

  render() {
    const moveToPage = this.moveToPage.bind(this);

    return (
      <ThemeProvider theme={theme}>
        <div id="app">
          {!this.state.pages.login && !this.state.pages.askAuth &&
            <AppBarComponent
              isContact={this.state.pages.contact}
              // isSettings={this.state.pages.settings}
              moveToPage={moveToPage}
            />
          }

          {this.state.pages.login &&
            <LoginComponent
              setLocalEncryptionKey={this.setLocalEncryptionKey.bind(this)}
              moveToPage={moveToPage}
            ></LoginComponent>}


          {this.state.pages.devices &&
            <DevicesComponent localEncryptionKey={this.localEncryptionKey}></DevicesComponent>}

          {this.state.pages.contact &&
            <ContactComponent localEncryptionKey={this.state.localEncryptionKey} />}

          {this.state.pages.settings &&
            <SettingsComponent moveToPage={moveToPage} />}

          {this.state.pages.importExport &&
            <ImportExportComponent localEncryptionKey={this.localEncryptionKey}/>}

          {this.state.pages.askAuth &&
            <AskForAuthComponent
              localEncryptionKey={this.localEncryptionKey}
              moveToPage={moveToPage} />}
        </div >
      </ThemeProvider>
    )
  }
}


