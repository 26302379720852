import { createMuiTheme } from '@material-ui/core/styles';


export const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#80cbc4',
        },
        secondary: {
            main: '#004d40',
        },
    },
});