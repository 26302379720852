import React from 'react';
import ReactDOM from "react-dom";

import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import { Device } from "../libs/objects";
import { loadAndSaveToken, setPublicKey } from "../libs/auth";

import { isServerInitializable, loadEncryptionKey, saltExists, verifyLocalEncryptedKeys, getPublicKey } from "../libs/init";
import Axios from 'axios';

export type LoginComponentProps = {
    setLocalEncryptionKey: (key: Uint8Array) => Promise<void>
    moveToPage: (page: string) => void
}

export type LoginComponentState = {
    loading: boolean;
    heavyWork: boolean;

    doesSaltExists: boolean;

    password: String;

    passwordNotValid: boolean;
    passwordConfirmationNotValid: boolean;
}

export default class LoginComponent extends React.Component<LoginComponentProps, LoginComponentState> {
    actualPass = "";
    constructor(props: LoginComponentProps) {
        super(props);

        this.state = {
            loading: true,
            heavyWork: false,
            doesSaltExists: false,
            passwordNotValid: false,
            passwordConfirmationNotValid: false,
            password: ""
        };

        saltExists().then((exists) => {
            this.setState({ doesSaltExists: exists });
        }).catch(() => {
            this.setState({ doesSaltExists: false });
        }).finally(() => {
            this.setState({ loading: false });
        })
    }

    async manageServerNeedsInit(cli: Device): Promise<boolean> {
        // Contact the server to know if the init is needed
        const initServer = await isServerInitializable().then((resp) => {
            return resp;
        });


        if (initServer) {
            return await Axios.post("/api/init", cli).then(() => {
                return true;
            }).catch(() => {
                return false;
            }).finally(() => { this.props.moveToPage("contact") });
        } else {
            Axios.post("/api/auth", {})
                .then(() => {
                    console.info("device authenticated");
                    this.props.moveToPage("contact");
                })
                .catch(() => {
                    console.info("device not authenticated");
                    this.props.moveToPage("askAuth");
                })
            return false;
        }
    }

    changedPassword(ev: React.FormEvent) {
        const input = ev.currentTarget as HTMLInputElement;
        this.actualPass = input.value
        this.setState({ password: this.actualPass });
    }

    async subPassword(ev: React.FormEvent | React.MouseEvent, init: boolean) {
        this.setState({ loading: true });

        ev.preventDefault();

        let cliName = "";

        if (init) {
            const pass = ((ReactDOM.findDOMNode(this) as HTMLHtmlElement).querySelector(".pass input") as HTMLInputElement).value;
            const conf = ((ReactDOM.findDOMNode(this) as HTMLHtmlElement).querySelector(".conf input") as HTMLInputElement).value;
            cliName = ((ReactDOM.findDOMNode(this) as HTMLHtmlElement).querySelector(".name input") as HTMLInputElement).value;

            if (pass === "" || pass !== conf) {
                this.setState({ loading: false, passwordConfirmationNotValid: true });
                return;
            }

            this.setState({ passwordConfirmationNotValid: false });
            this.actualPass = pass;
        }

        this.setState({ loading: true, heavyWork: true });
        var date1 = new Date();
        const key = await loadEncryptionKey(this.actualPass);
        var date2 = new Date();
        var diff = date2.getTime() - date1.getTime();

        this.setState({ heavyWork: false });

        console.info("Key derivation took", diff, "ms");

        const validPass = await verifyLocalEncryptedKeys(key);
        if (!validPass) {
            this.setState({ passwordNotValid: true, loading: false });
            console.error("password is not valid");
            return;
        }

        let publicKey = await getPublicKey(key);
        let cli = new Device(publicKey);
        if (init) {
            cli.name = cliName;
            cli.save();
        } else {
            await cli.load();
        }

        await loadAndSaveToken(key);

        setPublicKey(cli.public_key_base64);
        console.info("password decrypted the local keys");

        // Set the local password to the app component
        await this.props.setLocalEncryptionKey(key);
        
        console.info("run into the verification of the server stat");
        await this.manageServerNeedsInit(cli);
    }

    render() {
        this.subPassword.bind(this);

        const DerivePassword = (
            <form className="password" onSubmit={(e) => this.subPassword(e, false)}>
                <TextField
                    fullWidth
                    autoFocus
                    label="Enter local encryption password"
                    margin="normal"
                    type="password"
                    onChange={this.changedPassword.bind(this)}
                    value={this.state.password}
                />
                <Button color="primary" onClick={(e) => this.subPassword(e, false)}>
                    Submit
                </Button>
            </form>
        )

        const InitializingPassword = (
            <form
                className="password"
                action=""
                method="post"
                onSubmit={(e) => this.subPassword(e, true)}
            >
                <Typography variant="body1" className="explication">
                    Enter your password.
                    <br />
                    <strong>Remember it because there is no way to restore the password and the encrypted data.</strong>
                    <br />
                    All the encryption and the signing process is done in the browser.
                    This way, the server can't help you restore your password.
                </Typography>

                {this.state.passwordConfirmationNotValid ? BadConfirmationPasswordComponent : null}
                {this.state.passwordNotValid ? BadPasswordComponent : null}

                <TextField
                    fullWidth
                    autoFocus
                    label="Enter local encryption password"
                    margin="normal"
                    className="pass"
                    type="password"
                />
                <TextField
                    fullWidth
                    label="Enter local encryption password confirmation"
                    margin="normal"
                    className="conf"
                    type="password"
                />
                <TextField
                    fullWidth
                    label="The name referencing this device"
                    margin="normal"
                    className="name"
                />
                <Button type="submit" color="primary" onClick={(e: React.FormEvent) => this.subPassword(e, true)}>
                    Submit
                </Button>
            </form>
        )

        const content = (
            this.state.doesSaltExists ? DerivePassword : InitializingPassword
        );

        const loading = (
            this.state.heavyWork ? <CircularProgress disableShrink /> : <CircularProgress />
        )

        return (
            <div className="login">
                {
                    this.state.loading ?
                        (loading) :
                        (content)
                }
            </div>
        )
    }
}

const BadPasswordComponent = (
    <Paper className="errorPaper">
        <Typography variant="h5" component="h2">
            Password not valid
        </Typography>
        <Typography component="p">
            This password can't decrypt the saved keys.
            <br />
            Most likely the password is not correct.
        </Typography>
    </Paper>
)

const BadConfirmationPasswordComponent = (
    <Paper className="errorPaper">
        <Typography variant="h5" component="h2">
            Password not valid or empty
        </Typography>
        <Typography component="p">
            The two password must be equal.
        </Typography>
    </Paper>
)
