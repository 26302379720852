import React from 'react';

import { Note } from '../libs/objects';

import { DeleteDialog } from "./dialogs";

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import NoteIcon from '@material-ui/icons/Note';
import NotesIcon from '@material-ui/icons/Notes';

export class PropsM {
    notes: Note[] = [];
    updated: (notes: Note[]) => void = (_) => { return };
}

export class StateM {
    notes: Note[] = [];
}

export class NotesComponent extends React.Component<PropsM, StateM> {
    constructor(props: PropsM) {
        super(props);
        this.state = { notes: props.notes };
    }

    static getDerivedStateFromProps(nextProps: PropsM, _: StateM): StateM {
        return { notes: nextProps.notes };
    }

    updateNotes(notes: Note[]) {
        this.setState({ notes: this.state.notes });
        this.props.updated(notes);
    }
    updateNote(note: Note) {
        let notes = this.state.notes;
        notes[note.order.valueOf()] = note;
        this.setState({notes: notes});
        this.props.updated(notes);
    }

    addNote() {
        const newNum = new Note();
        newNum.lib = "";
        newNum.comment = "";
        newNum.order = this.state.notes.length;

        this.state.notes.push(newNum);
        this.props.updated(this.state.notes);
    }

    deleteNote(note: Note) {
        let newList: Note[] = [];

        for (const existingNote of this.state.notes) {
            if (existingNote.id === note.id) {
                continue
            }

            if (existingNote.order > note.order) {
                existingNote.order = (existingNote.order as number) - 1;
            }

            newList.push(existingNote);
        }

        this.updateNotes(newList);
    }

    render() {
        const listNotes = this.state.notes.map((note) =>
            // Correct! Key should be specified inside the array.
            <NoteComponent
                key={note.id}
                note={note}
                updated={this.updateNote.bind(this)}
                deleted={this.deleteNote.bind(this)}
            />
        );

        return (
            <div className="notesList">
                <div className="titleButtonGroup">
                    <Typography variant="h6">Notes</Typography>
                    <Button variant="outlined" color="primary" className="add"
                        onClick={this.addNote.bind(this)} size="small">
                        Add note
                    </Button>
                </div>
                {listNotes}
            </div>
        )
    }
}

class Props {
    note: Note = new Note();
    key: string = "";
    updated: (note: Note) => void = () => { };
    deleted: (note: Note) => void = () => { };
}

class States extends Note {
    delDialogOpen: boolean = false;
}

export class NoteComponent extends React.Component<Props, States> {
    constructor(props: Props) {
        super(props);

        const state = props.note as States;
        state.delDialogOpen = false;

        this.state = state;
    }

    static getDerivedStateFromProps(nextProps: Props, states: States): States {
        let ret = nextProps.note as States;
        ret.delDialogOpen = states.delDialogOpen;
        return ret;
    }


    // Delete
    askToDeleteNote() {
        this.setState({ delDialogOpen: true } as States);
    }
    onClose(_: React.MouseEvent) {
        this.setState({ delDialogOpen: false } as States);
    }
    onConfirm(_: React.MouseEvent, note: any) {
        console.debug("delete the note: ",note);
        this.props.deleted(note);
    }

    // Edit fields handling
    onLibChange(event: React.FormEvent) {
        const val = this.getValFromEvent(event);
        let note = {} as States;
        Object.assign(note, this.state);
        note.lib = val;
        this.updated(note)
    }
    onCommentChange(event: React.FormEvent) {
        const val = this.getValFromEvent(event);
        let note = {} as States;
        Object.assign(note, this.state);
        note.comment = val;
        this.updated(note)
    }
    getValFromEvent(e: React.FormEvent): string {
        const input = e.currentTarget as HTMLInputElement;
        return input.value;
    }
    updated(note: States) {
        this.setState(note);
        this.props.updated(note);
    }

    render() {
        return (
            <Paper className="noteLine" id={this.state.id} style={({
                order: this.state.order.valueOf()
            })}>
                <div className="order">{this.state.order}</div>
                <div className="lib">
                    <FormControl fullWidth variant="outlined" className="line">
                        <InputLabel htmlFor="outlined-adornment-amount">Label</InputLabel>
                        <OutlinedInput
                            placeholder="New note label"
                            margin="dense"
                            onChange={this.onLibChange.bind(this)}
                            value={this.state.lib}
                            startAdornment={<InputAdornment position="start"><NoteIcon /></InputAdornment>}
                            labelWidth={40}
                        />
                    </FormControl>
                </div>
                <Button size="small" className="del" color="secondary" variant="outlined"
                    onClick={this.askToDeleteNote.bind(this, this.state)}>
                    Delete
                </Button>
                <div className="note">
                    <FormControl fullWidth variant="outlined" className="line">
                        <InputLabel htmlFor="outlined-adornment-amount">Note</InputLabel>
                        <OutlinedInput
                            margin="dense"
                            placeholder="I need to ..."
                            multiline
                            fullWidth
                            onChange={this.onCommentChange.bind(this)}
                            value={this.state.comment}
                            startAdornment={<InputAdornment position="start"><NotesIcon /></InputAdornment>}
                            labelWidth={40}
                        />
                    </FormControl>
                </div>
                {this.state.delDialogOpen &&
                    <DeleteDialog
                        lib={this.state.lib}
                        elem={this.state as any}
                        onClose={this.onClose.bind(this)}
                        onConfirm={this.onConfirm.bind(this)}></DeleteDialog>
                }
            </Paper>
        )
    }
}