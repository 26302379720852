import React, { Component } from 'react';

import { Contact, Address, Phone, Note } from '../libs/objects';
import { theme } from '../libs/theme';

import Search from "./search";
import { AddressComponent } from './address';
import { PhonesComponent } from './phone';
import { NotesComponent } from './note';
import { DeleteDialog } from './dialogs';
import { getAllContacts, getFirstContact, setContact, deleteContact } from "../libs/contact";
import { Block, getRemoteBlocks, startBlockListener, getLastLocallySavedBlock } from '../libs/blocks';

import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Paper from '@material-ui/core/Paper';
import BusinessIcon from '@material-ui/icons/Business';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
// import Axios from 'axios';

export class Props {
    // contact: Contact;
    localEncryptionKey: Uint8Array = new Uint8Array(0);
    // serverEncryptionKey: Uint8Array = new Uint8Array(0);
}

export class State {
    contact: Contact = new Contact("");
    // inEdition: boolean = false;
    showDelModal: boolean = false;

    searchTimeItTook: number = 0;
    searchPosition: number = 0;
    searchTotalLoadedContacts: number = 0;

    snackbarUpgradedOpen: boolean = false;

    online: boolean = window.navigator.onLine;
}

export default class ContactComponent extends Component<Props, State> {
    contact: Contact;
    contactList: Contact[] = [];
    position: number = 0;
    lastKnownBlock: Block = new Block(0, "");
    intervalID: number = 0;

    previousSearch: string = "";

    timeoutUpgraded: number = 0;

    constructor(props: Props) {
        super(props);
        this.contact = new Contact("");
        const state = new State();
        state.contact = this.contact;
        this.state = state;


        this.setFirstContact();

        const thisObj = this;
        getLastLocallySavedBlock(this.props.localEncryptionKey).then((b) => {
            thisObj.lastKnownBlock = b;
        }).catch(() => { });
    }

    async loadContactList() {
        const t0 = new Date();
        this.contactList = await getAllContacts(this.props.localEncryptionKey);
        const t1 = new Date();
        const timeItTook = t1.getTime() - t0.getTime();

        this.setState({ searchTimeItTook: timeItTook, searchTotalLoadedContacts: this.contactList.length })
    }

    async setFirstContact() {
        this.contact = await getFirstContact(this.props.localEncryptionKey).then((contact) => {
            // If we found contact locally it means the server must be initialized to
            return contact
        }).catch(async () => {
            // Check if the server is also empty
            return await getRemoteBlocks(0, 0).then(async () => {
                return new Contact("");
            }).catch(async () => {
                console.info("Server has no recorded blocks. Let's start ");

                const c = new Contact("");
                await setContact(this.props.localEncryptionKey, c);
                return c;
            });

        }).finally(() => {
            startBlockListener(this.props.localEncryptionKey, this.notifyBlocksUpdated.bind(this), this.notifyOnline.bind(this)).then((intervalID) => {
                this.intervalID = intervalID;
            });
        });

        window.contact = this.contact;

        this.saveState();

        this.loadContactList();
    }

    saveState() {
        this.setState({ contact: this.contact } as State)
    }

    notifyOnline(online: boolean) {
        this.setState({ online });
    }

    changeCompany(e: React.FormEvent) {
        const input = e.currentTarget as HTMLInputElement;

        this.contact.company = input.value;
        this.saveState();
    }
    addressUpdated(addr: Address) {
        this.contact.address = addr;
        this.saveState();
    }
    phonesUpdated(phones: Phone[]) {
        this.contact.phones = phones;
        this.saveState();
    }
    notesUpdated(notes: Note[]) {
        this.contact.notes = notes;
        this.saveState();
    }

    onClickSave() {
        setContact(this.props.localEncryptionKey, this.contact);
    }
    async onClickDel() {
        this.setState({ showDelModal: true });
    }

    componentDidMount() {
        this.setBindings();
    }
    componentWillUnmount() {
        document.onkeyup = null;
        clearInterval(this.intervalID);
    }
    setBindings() {
        document.onkeyup = function (e: KeyboardEvent) {
            if (e.ctrlKey && e.altKey && (e.key === "n" || e.key === "N")) {
                console.error("not implemented NEW contact");
            }
        };
    }

    moveContactUpClick(e: React.MouseEvent) {
        this.moveContactUp();
    }
    async moveContact(way: number) {
        this.doUpdate();

        const nextPos = this.position + way;

        if (nextPos >= this.contactList.length) {
            return
        }
        else if (nextPos < 0) {
            return
        }

        const tmpContact = this.contactList[nextPos];
        if (!tmpContact) {
            return;
        }

        this.position = nextPos;
        this.setState({ searchPosition: nextPos })
        this.setContactToDisplay(tmpContact)
    }
    moveContactUp() {
        this.moveContact(+1);
    }
    moveContactDownClick(e: React.MouseEvent) {
        this.moveContactDown();
    }
    moveContactDown() {
        this.moveContact(-1);
    }
    setEmptyContact() {
        console.info("Display new empty contact.");
        this.setState({ searchPosition: 0, searchTimeItTook: 0, searchTotalLoadedContacts: 0 })
        const tmpContact = new Contact("");
        this.setContactToDisplay(tmpContact);
    }
    // doUpdate updates the contacts list if needed and update the screen if needed
    async doUpdate() {
        const isFresh = await this.isUpToDate();
        if (!isFresh) {
            this.loadContactList().then(async () => {
                await this.search(this.previousSearch, true)
            }).finally(async () => {
                await getLastLocallySavedBlock(this.props.localEncryptionKey).then((lastBlock) => {
                    this.lastKnownBlock = lastBlock;
                })
            });
        }
    }
    async isUpToDate(): Promise<boolean> {
        return await getLastLocallySavedBlock(this.props.localEncryptionKey).then((lastBlock) => {
            return (this.lastKnownBlock.hash === lastBlock.hash) ? true : false;
        }).catch(() => { return false });
    }

    setContactToDisplay(c: Contact) {
        console.debug("setContactToDisplay", c);

        // check sanity
        let cleanPhones: Phone[] = [];
        let cleanPhone = true; // true if the phones are clean
        let phoneCleanOrder = true; // true if the phones order has no problem
        let cleanNotes: Note[] = [];
        let cleanNote = true;
        let noteCleanOrder = true;
        for (const phone of c.phones) {
            if (!phone) {
                cleanPhone = false;
                continue;
            }
            let added = false;
            for (const p of cleanPhones) {
                if (p.id === phone.id) {
                    let tmpP = new Phone();
                    tmpP.lib = phone.lib;
                    tmpP.num = phone.num;
                    phoneCleanOrder = false;
                    cleanPhones.push(tmpP);
                    added = true;
                    break;
                }
            }
            if (!added) {
                cleanPhones.push(phone);
            }
        }

        for (const note of c.notes) {
            if (!note) {
                cleanNote = false;
                continue;
            }
            let added = false;
            for (const n of cleanNotes) {
                if (n.id === note.id) {
                    let tmpN = new Note();
                    tmpN.lib = note.lib;
                    tmpN.comment = note.comment;
                    phoneCleanOrder = false;
                    cleanNotes.push(tmpN);
                    added = true;
                    break;
                }
            }
            if (!added) {
                cleanNotes.push(note);
            }
        }
        if (!cleanPhone || !phoneCleanOrder) {
            console.error(c.company + ": the phones cleanup needed", cleanPhone, phoneCleanOrder, c.phones);
            if (!phoneCleanOrder) {
                let reordered = [];
                let i = 0;
                for (const phone of cleanPhones) {
                    phone.order = i
                    reordered.push(phone);
                    i += 1;
                }

                cleanPhones = reordered;
            }
        }
        if (!cleanNote || !noteCleanOrder) {
            console.error(c.company + ": the notes cleanup needed", cleanNote, noteCleanOrder, c.notes);
            if (!noteCleanOrder) {
                let reordered = [];
                let i = 0;
                for (const note of cleanNotes) {
                    note.order = i
                    reordered.push(note);
                    i += 1;
                }

                cleanNotes = reordered;
            }
        }

        c.phones = cleanPhones
        c.notes = cleanNotes

        this.contact = c;
        window.contact = c;
        this.cleanHeightLight();
        this.setState({ contact: c }, () => {
            if (this.previousSearch) {
                this.setHeightLight(this.previousSearch);
            }
        })

    }
    setHeightLight(heightLight: string) {
        const reg = new RegExp(heightLight, "i");
        const baseContainer = document.querySelector("#app .contact .contactContainer");
        if (baseContainer) {
            if (reg.test(this.contact.company)) {
                baseContainer.querySelector(".line.company")?.classList.add("heightLight")
            }
            // Address
            if (reg.test(this.contact.address.contact)) {
                baseContainer.querySelector(".line.contactName")?.classList.add("heightLight")
            }
            if (reg.test(this.contact.address.street)) {
                baseContainer.querySelector(".line.street")?.classList.add("heightLight")
            }
            if (reg.test(this.contact.address.city)) {
                baseContainer.querySelector(".line.city")?.classList.add("heightLight")
            }
            if (reg.test(this.contact.address.zip)) {
                baseContainer.querySelector(".line.zipCode")?.classList.add("heightLight")
            }
            if (reg.test(this.contact.address.country)) {
                baseContainer.querySelector(".line.country")?.classList.add("heightLight")
            }
            // Phones
            for (const phone of this.contact.phones) {
                if (reg.test(phone.lib)) {
                    document.getElementById(phone.id)?.querySelector(".lib")?.classList.add("heightLight")
                }
                if (reg.test(phone.num)) {
                    document.getElementById(phone.id)?.querySelector(".num")?.classList.add("heightLight")
                }
            }
            // Notes
            for (const note of this.contact.notes) {
                if (reg.test(note.lib)) {
                    document.getElementById(note.id)?.querySelector(".lib")?.classList.add("heightLight")
                }
                if (reg.test(note.comment)) {
                    document.getElementById(note.id)?.querySelector(".note")?.classList.add("heightLight")
                }
            }
        }
    }
    cleanHeightLight() {
        document.querySelectorAll("#app .contact .contactContainer .heightLight").forEach((elem) => {
            elem.classList.remove("heightLight");
        })
    }

    notifyBlocksUpdated() {
        this.setState({ snackbarUpgradedOpen: true })
        window.clearTimeout(this.timeoutUpgraded);
        this.timeoutUpgraded = window.setTimeout(() => {
            this.setState({ snackbarUpgradedOpen: false })
        }, 6500)
        this.doUpdate();
    }

    onCloseDelConf(e: React.MouseEvent) {
        this.setState({ showDelModal: false });
    }
    async onConfirmDelConf(e: React.MouseEvent) {
        this.setState({ showDelModal: false });
        await deleteContact(this.props.localEncryptionKey, this.contact);
        if (this.position === 0) {
            this.moveContactUp();
        } else if (this.contactList.length === 0) {
            console.info("no contact left in the list");
            this.setEmptyContact()
        } else {
            this.moveContactDown();
        }
    }

    async search(value: string, stayInPlace: boolean = false) {
        const comp = this;
        let previousPosition = this.state.searchPosition;
        if (!stayInPlace) {
            previousPosition = 0;
        }
        const displaySearchTime = (t0: Date, nb: number) => {
            const t1 = new Date();

            const timeItTook = t1.getTime() - t0.getTime();
            comp.setState({
                searchTimeItTook: timeItTook,
                searchPosition: previousPosition,
                searchTotalLoadedContacts: nb,
            });

            this.previousSearch = value;

            console.info(
                "search for \"" +
                value +
                "\" took " +
                timeItTook +
                "ms and found " +
                nb +
                " contacts"
            )
        }

        const t0 = new Date();
        if (value === "") {
            await this.loadContactList();
            const c = this.contactList[0];
            this.position = 0;
            this.setContactToDisplay(c)
            displaySearchTime(t0, this.contactList.length);
            return;
        }

        const continueSearchTestReg = new RegExp("^" + this.previousSearch, "i");
        // The search is not continuous to the previous search
        if (this.previousSearch === "" || !continueSearchTestReg.test(value)) {
            console.info(
                "search not continuous the previous value was \"" +
                this.previousSearch +
                "\" and the new is \"" +
                value +
                "\""
            );
            await this.loadContactList();
        }

        let foundInHead: Contact[] = [];
        let foundInBody: Contact[] = [];

        const reg = new RegExp(value, "i");
        for (const c of this.contactList) {
            if (c.testHeadReg(reg)) {
                foundInHead.push(c);
                continue;
            }
            if (c.testBodyReg(reg)) {
                foundInBody.push(c);
            }
        }

        const result = foundInHead.concat(foundInBody);

        if (result.length === 0) {
            this.contactList = [];
            this.setEmptyContact();
            displaySearchTime(t0, 0);
            return;
        }

        this.contactList = result;
        this.position = previousPosition;

        displaySearchTime(t0, result.length);
        this.setContactToDisplay(this.contactList[previousPosition]);
    }

    render() {
        let classes: string = "contact";

        const navButtons = (
            <div className="navButtons">
                <Fab color="secondary" onClick={this.moveContactUpClick.bind(this)} aria-label="up" className="up">
                    <ArrowUpward />
                </Fab>
                <Fab color="secondary" onClick={this.moveContactDownClick.bind(this)} aria-label="down" className="down">
                    <ArrowDownward />
                </Fab>
            </div>
        );

        return (
            <div className={classes}>
                <div className="contactContainer">
                    <Typography variant="h6" >Contact information</Typography>
                    <FormControl fullWidth variant="outlined" className="line company">
                        <InputLabel htmlFor="outlined-adornment-amount">Company</InputLabel>
                        <OutlinedInput
                            margin="dense"
                            placeholder="Google"
                            onChange={this.changeCompany.bind(this)}
                            value={this.state.contact.company}
                            startAdornment={<InputAdornment position="start"><BusinessIcon /></InputAdornment>}
                            labelWidth={70}
                        />
                    </FormControl>
                    <AddressComponent updated={this.addressUpdated.bind(this)} address={this.state.contact.address} />
                    <PhonesComponent phones={this.state.contact.phones} updated={this.phonesUpdated.bind(this)} />
                    <NotesComponent notes={this.state.contact.notes} updated={this.notesUpdated.bind(this)} />
                </div>

                {this.state.showDelModal &&
                    <DeleteDialog lib={this.state.contact.company} onClose={this.onCloseDelConf.bind(this)} onConfirm={this.onConfirmDelConf.bind(this)} elem={{}}></DeleteDialog>}

                {isMobile() && navButtons}

                <footer className="bottomBar">
                    <Paper square className="editButtons" style={{ backgroundColor: theme.palette.primary.light }}>
                        <Button variant="outlined" onClick={this.onClickSave.bind(this)}>
                            save
                        </Button>
                        <Button variant="outlined" onClick={this.setEmptyContact.bind(this)}>
                            new
                        </Button>
                        <Button variant="outlined" color="secondary" onClick={this.onClickDel.bind(this)}>
                            delete
                        </Button>
                    </Paper>
                    <Search value=""
                        callBackUp={this.moveContactUp.bind(this)}
                        callBackDown={this.moveContactDown.bind(this)}

                        callBackSearch={this.search.bind(this)}

                        timeItTook={this.state.searchTimeItTook}
                        position={this.state.searchPosition}
                        totalLoadedContacts={this.state.searchTotalLoadedContacts}

                        online={this.state.online}
                    />
                </footer>
                <Snackbar open={this.state.snackbarUpgradedOpen}>
                    <MuiAlert severity="success">
                        Database ugraded from server
                    </MuiAlert>
                </Snackbar>
            </div >
        )
    }
}

export function isMobile(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}