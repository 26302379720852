import uuid from 'uuid/v4';

import localforage from "localforage";

import { getPublicKey } from "./auth";

// import uuidv4 from "uuid/v4";

export const LocalDevice_DB_ID = "localDevice";

export class Device {
    // public id: String;
    public name: string;
    public public_key_base64: string;
    public ecdh_public_key_base64: string;

    constructor(public_key?: Uint8Array) {
        this.name = "";
        this.ecdh_public_key_base64 = "";

        if (public_key) {
            this.public_key_base64 = this.bufferToBase64(public_key);
        } else {
            this.public_key_base64 = ""
        }
    }

    /**
     * loadDevice
     */
    public async load(): Promise<boolean> {
        return await localforage.getItem(LocalDevice_DB_ID).then((ret) => {
            if (ret) {
                const cli = ret as Device;
                // this.id = cli.id;
                this.name = cli.name;
                this.public_key_base64 = cli.public_key_base64;
                return true;
            } else {
                console.error("not loaded");
                return false;
            }
        }).catch((err) => {
            console.error(err);
            return false
        });
    }

    /**
     * saveDevice
     */
    public async save() {
        let toSave = JSON.parse(JSON.stringify(this)) as Device;
        delete toSave.ecdh_public_key_base64;
        await localforage.setItem(LocalDevice_DB_ID, toSave);
    }

    public setPublicKey(pub: Uint8Array) {
        this.public_key_base64 = this.bufferToBase64(pub);
    }

    private bufferToBase64(buf: Uint8Array): string {
        var binstr = Array.prototype.map.call(buf, function (ch) {
            return String.fromCharCode(ch);
        }).join('');
        return btoa(binstr);
    }
    // private base64ToBuffer(base64: string): Uint8Array {
    //     var binstr = atob(base64);
    //     var buf = new Uint8Array(binstr.length);
    //     Array.prototype.forEach.call(binstr, function(ch, i) {
    //         buf[i] = ch.charCodeAt(0);
    //     });
    //     return buf;
    // }
}


export class Contact {
    constructor(id: string) {
        if (!id && id === "") {
            this.id = uuid();
        } else {
            this.id = id;
        }

        const devicePublicKey = getPublicKey()

        this.creationDevice = devicePublicKey;
        this.lastModifyDevice = devicePublicKey;

        const date = new Date();
        this.creationDate = date;
        this.lastModify = date;
    }

    id: string;
    company: string = "";
    address: Address = new Address();
    phones: Phone[] = [];
    notes: Note[] = [];
    creationDate: Date = new Date();
    creationDevice: string;
    lastModify: Date = new Date();
    lastModifyDevice: string;
    toDelete: boolean = false;

    export(): string {
        // let ret = {
        //     id: this.id,
        //     company: this.company,
        //     address: this.address,
        //     phones: this.phones,
        //     notes: this.notes,
        //     creationDate: this.creationDate,
        //     creationDevice: this.creationDevice,
        //     lastModify: this.lastModify,
        //     lastModifyDevice: this.lastModifyDevice
        // };

        return JSON.stringify(this);
    }

    exportToUint8Array(): Uint8Array {
        return new TextEncoder().encode(this.export());
    }

    parse(id: string, input: string) {
        let parsed = JSON.parse(input) as Contact;

        if (id === "") {
            this.id = parsed.id;
        } else {
            this.id = id;
        }
        this.company = parsed.company;
        this.address = parsed.address;
        this.phones = parsed.phones;
        this.notes = parsed.notes;
        this.creationDate = parsed.creationDate;
        this.creationDevice = parsed.creationDevice;
        this.lastModify = parsed.lastModify;
        this.lastModifyDevice = parsed.lastModifyDevice;
        this.toDelete = parsed.toDelete;
    }

    parseFromUint8Array(id: string, input: Uint8Array) {
        const asString = new TextDecoder().decode(input);
        this.parse(id, asString);
    }

    testFullReg(reg: RegExp): boolean {
        if (this.testHeadReg(reg)) {
            return true;
        }
        if (this.testBodyReg(reg)) {
            return true;
        }
        return false;
    }
    testHeadReg(reg: RegExp): boolean {
        if (reg.test(this.company)) {
            return true;
        }
        if (reg.test(this.address.contact)) {
            return true;
        }
        if (reg.test(this.address.street)) {
            return true;
        }
        if (reg.test(this.address.city)) {
            return true;
        }
        if (reg.test(this.address.zip)) {
            return true;
        }
        if (reg.test(this.address.country)) {
            return true;
        }
        return false;
    }
    testBodyReg(reg: RegExp): boolean {
        for (const phone of this.phones) {
            if (!phone) {
                console.error("one phone of " + this.company + " is null");

                continue;
            }
            if (reg.test(phone.lib)) {
                return true;
            }
            if (reg.test(phone.num)) {
                return true;
            }
        }
        for (const note of this.notes) {
            if (reg.test(note.lib)) {
                return true;
            }
            if (reg.test(note.comment)) {
                return true;
            }
        }
        return false
    }
}

export class Address {
    contact: string = "";
    street: string = "";
    city: string = "";
    zip: string = "";
    country: string = "";
}

export class SimpleFields {
    id: string;
    lib: string = "";
    order: Number = 0;

    constructor(id?: string) {
        if (!id) {
            this.id = uuid();
        } else {
            this.id = id;
        }
    }
}

export class Phone extends SimpleFields {
    num: string = "";

    asExport(): object {
        return {
            id: this.id,
            lib: this.lib,
            num: this.num
        };
    }
}

export class Note extends SimpleFields {
    comment: string = "";

    asExport(): object {
        return {
            id: this.id,
            lib: this.lib,
            comment: this.comment
        };
    }
}