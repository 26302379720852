import React from 'react';

import { Address } from '../libs/objects';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';

import ContactMail from '@material-ui/icons/ContactMail';
import LocalShipping from '@material-ui/icons/LocalShipping';
import LocationCity from '@material-ui/icons/LocationCity';
import CodeIcon from '@material-ui/icons/Code';
import FlagIcon from '@material-ui/icons/Flag';

export type AddressProp = {
    address: Address;
    updated: (addr: Address) => void;
}

export class AddressComponent extends React.Component<AddressProp, Address> {
    address: Address;

    constructor(props: AddressProp) {
        super(props);
        this.address = props.address;
        this.state = {
            contact: props.address.contact,
            street: props.address.street,
            city: props.address.city,
            zip: props.address.zip,
            country: props.address.country
        }
    }

    static getDerivedStateFromProps(nextProps: AddressProp, nextStates: Address): Address {
        return nextProps.address
    }

    changeContact(e: React.FormEvent) {
        const contact = this.getValFromEvent(e);
        this.address.contact = contact;
        this.props.updated(this.address);
    }
    changeStreet(e: React.FormEvent) {
        const street = this.getValFromEvent(e);
        this.address.street = street;
        this.props.updated(this.address);
    }
    changeCity(e: React.FormEvent) {
        const city = this.getValFromEvent(e);
        this.address.city = city;
        this.props.updated(this.address);
    }
    changeZip(e: React.FormEvent) {
        const zip = this.getValFromEvent(e);
        this.address.zip = zip;
        this.props.updated(this.address);
    }
    changeCountry(e: React.FormEvent) {
        const country = this.getValFromEvent(e);
        this.address.country = country;
        this.props.updated(this.address);
    }

    getValFromEvent(e: React.FormEvent): string {
        this.address = this.state;
        const input = e.currentTarget as HTMLInputElement;
        return input.value;
    }

    // updateState() {
    //     const newState = Object.assign({}, this.state)
    //     this.setState(newState);
    //     this.props.updated(newState);
    // }

    render() {
        return (
            <div className="address">
                <FormControl fullWidth variant="outlined" className="line contactName">
                    <InputLabel htmlFor="outlined-adornment-amount">Contact Name</InputLabel>
                    <OutlinedInput
                        margin="dense"
                        placeholder="Larry Page"
                        onChange={this.changeContact.bind(this)}
                        value={this.state.contact}
                        startAdornment={<InputAdornment position="start"><ContactMail /></InputAdornment>}
                        labelWidth={110}
                    />
                </FormControl>
                <FormControl fullWidth variant="outlined" className="line street">
                    <InputLabel htmlFor="outlined-adornment-amount">Street</InputLabel>
                    <OutlinedInput
                        margin="dense"
                        placeholder="George St"
                        onChange={this.changeStreet.bind(this)}
                        value={this.state.street}
                        startAdornment={<InputAdornment position="start"><LocalShipping /></InputAdornment>}
                        labelWidth={50}
                    />
                </FormControl>
                <FormControl fullWidth variant="outlined" className="line city">
                    <InputLabel htmlFor="outlined-adornment-amount">City</InputLabel>
                    <OutlinedInput
                        margin="dense"
                        placeholder="San Francisco"
                        onChange={this.changeCity.bind(this)}
                        value={this.state.city}
                        startAdornment={<InputAdornment position="start"><LocationCity /></InputAdornment>}
                        labelWidth={30}
                    />
                </FormControl>
                <FormControl fullWidth variant="outlined" className="line zipCode">
                    <InputLabel htmlFor="outlined-adornment-amount">Zip code</InputLabel>
                    <OutlinedInput
                        margin="dense"
                        placeholder="94 102"
                        onChange={this.changeZip.bind(this)}
                        value={this.state.zip}
                        startAdornment={<InputAdornment position="start"><CodeIcon /></InputAdornment>}
                        labelWidth={70}
                    />
                </FormControl>
                <FormControl fullWidth variant="outlined" className="line country">
                    <InputLabel htmlFor="outlined-adornment-amount">Country</InputLabel>
                    <OutlinedInput
                        margin="dense"
                        placeholder="California USA"
                        onChange={this.changeCountry.bind(this)}
                        value={this.state.country}
                        startAdornment={<InputAdornment position="start"><FlagIcon /></InputAdornment>}
                        labelWidth={60}
                    />
                </FormControl>
            </div>
        )
    }
}
