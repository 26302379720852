import axios from "axios";
import localforage from "localforage";

const encryptionSalt_DB_ID = "encryptionSalt";
export const encryptedLocalKeys_DB_ID = "encryptedLocalKeys";
export const blockEncryptionKey_DB_ID = "blockEncryptionKey";
export const localEncryptionKey_DB_ID = "localEncryptionKey";

export async function isServerInitializable(): Promise<boolean> {
    return await axios.head("/api/init").then(() => {
        return true;
    }).catch(() => {
        return false;
    })
}

export async function saltExists(): Promise<boolean> {
    return await localforage.getItem(encryptionSalt_DB_ID)
        .then((salt) => {
            if (!salt) {
                return false;
            }
            return true;
        }).catch(() => {
            return false;
        });
}

// It builds an encryption key by (loading from storage/generation) the salt
// and derive the given password to build a 32 bytes encryption key.
// This key will be used to encrypt the local pieces of information.
export async function loadEncryptionKey(password: String): Promise<Uint8Array> {
    // Check if there is already a saved salt
    const salt = await localforage.getItem(encryptionSalt_DB_ID)
        .then((salt) => {
            if (!salt) {
                throw new Error("no salt");
            }
            return salt;
        }).catch(() => {
            // If not it gen a new one and save it
            const salt = new Uint8Array(16);
            window.crypto.getRandomValues(salt);

            localforage.setItem(encryptionSalt_DB_ID, salt).catch((err) => {
                console.error("can't save the salt:", err);
            }).finally(() => {
                console.info("New salt saved");
            })
            console.info("salt not found in storage. Generated the salt:", salt);

            return salt;
        }) as Uint8Array;

    // Derive the password to get the encryption key
    const localEncryptionKey = await import("../wasm/index").then(async (wasm) => {
        let derivationValues = {
            memory: 5000, // 5MB
            cost: 2
        }

        if (process.env.NODE_ENV !== 'production') {
            derivationValues = {
                memory: 100, // 0.1MB
                cost: 1
            }
            console.warn("password is in dev mode", derivationValues);
        }

        const keyForLocalEncryptionKey = wasm.build_encryption_key_from_string_password(
            password.toString(),
            salt,
            derivationValues.memory,
            derivationValues.cost
        ) as Uint8Array;

        // Return the encryption key decrypted using the given password and the saved salt
        return await localforage.getItem(localEncryptionKey_DB_ID).then((encryptedLocalEncryptionKey) => {
            if (!encryptedLocalEncryptionKey) {
                throw new Error("no encrypted local encryption key");
            }
            return wasm.decrypt_content_symmetric(keyForLocalEncryptionKey, encryptedLocalEncryptionKey)
        }).catch(() => {
            // If not it gen a new one and save it
            const key = new Uint8Array(32);
            window.crypto.getRandomValues(key);
            const encryptedKey = wasm.encrypt_content_symmetric(keyForLocalEncryptionKey, key);
            localforage.setItem(localEncryptionKey_DB_ID, encryptedKey).catch((err) => {
                console.error(err);
            });
            return key;
        })
    });

    // Build the block encryption key
    await localforage.getItem(blockEncryptionKey_DB_ID)
        .then((blockKey) => {
            if (!blockKey) {
                throw new Error("no blockKey");
            }
            return blockKey;
        }).catch(async () => {
            // If not it gen a new one and save it
            const blockKey = new Uint8Array(32);
            window.crypto.getRandomValues(blockKey);

            const blockKeyEncrypted = await import("../wasm/index").then((wasm) => {
                return wasm.encrypt_content_symmetric(localEncryptionKey, blockKey) as Uint8Array;
            });

            localforage.setItem(blockEncryptionKey_DB_ID, blockKeyEncrypted).catch((err) => {
                console.error("can't save the block key:", err);
            }).finally(() => {
                console.info("New blockKey saved");
            })
            console.info("block key not found in storage. Generated the block key:", blockKey);

            return blockKey;
        });

    // const keys = await import("../wasm/index").then((wasm) => {
    //     return wasm.build_local_encrypted_keys(key) as Uint8Array;
    // });

    return localEncryptionKey;
}

// This function (build and save) or (get and decrypt to verify the saved keys) 
// and returns the local keys as encrypted values.
export async function verifyLocalEncryptedKeys(encryptionKey: Uint8Array): Promise<boolean> {
    const encryptedKeys = await localforage.getItem(encryptedLocalKeys_DB_ID).then((keys) => {
        if (!keys) {
            throw new Error("no key");
        }
        return keys as Uint8Array;
    }).catch(async () => {
        const keys = await import("../wasm/index").then((wasm) => {
            return wasm.build_local_encrypted_keys(encryptionKey) as Uint8Array;
        });
        localforage.setItem(encryptedLocalKeys_DB_ID, keys).catch((err) => {
            console.error("can't save the salt:", err);
        })

        return keys;
    }
    ) as Uint8Array;

    return await import("../wasm/index").then((wasm) => {
        return wasm.is_decryption_key_valid(encryptionKey, encryptedKeys);
    }).catch((err) => {
        console.error(err);
        return false;
    });
}

export async function getPublicKey(encryptionKey: Uint8Array): Promise<Uint8Array> {
    const encryptedKeys = await localforage.getItem(encryptedLocalKeys_DB_ID).then((keys) => {
        if (!keys) {
            throw new Error("no key");
        }
        return keys as Uint8Array;
    })

    return await import("../wasm/index").then((wasm) => {
        return wasm.get_clear_public_key(encryptionKey, encryptedKeys) as Uint8Array;
    });
}