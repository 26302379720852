import { Contact } from "./objects";
import { Block, getLastLocallySavedBlock, setLastSavedBlock } from "./blocks";
import * as auth from "./auth";

import localforage from "localforage";
import Axios from 'axios';

const saltLength = 16;

function getStorage(): LocalForage {
    return localforage.createInstance({
        name: "contacts"
    });
}

export function getTmpStorage(): LocalForage {
    return localforage.createInstance({
        name: "tmp-contacts"
    });
}

export async function getFirstContact(localEncryptionKey: Uint8Array): Promise<Contact> {
    // let contact: Contact;
    return await getStorage().iterate(async (content: Uint8Array, key: string, n: number) => {
        // console.log([key, content, n]);
        const clearContent = await decryptContact(localEncryptionKey, content);

        let contact = new Contact(key);
        contact.parseFromUint8Array(key, clearContent);

        return contact;
        // contact = iContact
    }).then((c) => {
        // console.log(c, c ? true : false);
        if (c) {
            return c;
        }
        throw new Error("db empty")
    }).catch(() => {
        throw new Error("db empty")
    }) as Contact;
}

export async function setContact(localEncryptionKey: Uint8Array, c: Contact): Promise<void> {
    c.lastModify = new Date();
    c.lastModifyDevice = auth.getPublicKey();
    return await setContactToServer(localEncryptionKey, c).then(async (newBlock) => {
        await setContactLocally(localEncryptionKey, newBlock, c);
    });
}

export async function setContactLocally(localEncryptionKey: Uint8Array, newBlock: Block, c: Contact): Promise<void> {
    const salt = new Uint8Array(saltLength);
    window.crypto.getRandomValues(salt);

    const cAsBytes = c.exportToUint8Array();

    await setLastSavedBlock(localEncryptionKey, newBlock);
    const content = await encryptContact(localEncryptionKey, salt, cAsBytes);

    await getStorage().setItem(c.id, content);
}

export async function deleteContact(localEncryptionKey: Uint8Array, c: Contact): Promise<void> {
    c.toDelete = true;

    return await setContactToServer(localEncryptionKey, c).then(async (newBlock) => {
        await deleteContactLocally(localEncryptionKey, c.id, newBlock);
    });
}
export async function deleteContactLocally(localEncryptionKey: Uint8Array, id: string, newBlock: Block): Promise<void> {
    await getStorage().removeItem(id);
    await setLastSavedBlock(localEncryptionKey, newBlock);
}

// async function setContactLocally(localEncryptionKey: Uint8Array, c: Contact): Promise<Uint8Array> {
//     const salt = new Uint8Array(saltLength);
//     window.crypto.getRandomValues(salt);

//     const cAsBytes = c.exportToUint8Array();

//     const content = await encryptContact(localEncryptionKey, salt, cAsBytes);

//     await setContactToServer(localEncryptionKey, c);

//     return await getStorage().setItem(c.id, content)
// }

export async function setContactToServer(localEncryptionKey: Uint8Array, contact: Contact): Promise<Block> {
    const blockKey = await auth.getClearBlockKey(localEncryptionKey);

    const salt = new Uint8Array(saltLength);
    window.crypto.getRandomValues(salt);

    contact.lastModify = new Date();
    contact.lastModifyDevice = auth.getPublicKey();

    const encryptedContact = await encryptContact(blockKey, salt, contact.exportToUint8Array());
    const blockHash = await import("../wasm/index").then((wasm) => {
        return wasm.hash_block_to_base64(encryptedContact);
    })

    if (navigator.onLine) {
        return await Axios.put("/api/blocks", encryptedContact, {
            headers: {
                "Content-Type": "application/octet-stream",
                "Accept": "text/plain"
            }
        }).then((r) => {
            const ret = new Block(r.data as number, blockHash);
            return ret;
        }).catch((e) => {
            console.error(e);
            return new Block(0, blockHash);
        });
    } else {
        await getTmpStorage().setItem(contact.id, encryptedContact);
        const localSavedBlock = await getLastLocallySavedBlock(localEncryptionKey);

        return localSavedBlock;
    }
}

export async function blockToContact(localEncryptionKey: Uint8Array, block: Uint8Array): Promise<Contact> {
    let blockKey = await auth.getClearBlockKey(localEncryptionKey);

    const contactAsBytes = await decryptContact(blockKey, block);

    let c = new Contact("");
    c.parseFromUint8Array("", contactAsBytes);
    return c
}

export async function encryptContact(key: Uint8Array, salt: Uint8Array, content: Uint8Array): Promise<Uint8Array> {
    let encrypted = await import("../wasm/index").then((wasm) => {
        return wasm.compress_and_encrypt(key, salt, content);
    });

    const ret = new Uint8Array([...salt, ...encrypted]);

    return ret;
}

export async function decryptContact(key: Uint8Array, content: Uint8Array): Promise<Uint8Array> {
    // console.log(content.length);
    // console.log(content);

    const salt = content.slice(0, saltLength);
    const contentOnly = content.slice(saltLength, content.length);
    // console.log(salt.length, contentOnly.length);
    // console.log(salt, contentOnly);

    return await import("../wasm/index").then((wasm) => {
        return wasm.decrypt_and_decompress(key, salt, contentOnly);
    });
}

export async function getAllContacts(localEncryptionKey: Uint8Array): Promise<Contact[]> {
    const storage = getStorage();
    let ret: Contact[] = [];
    ret.length = await storage.length();

    await getStorage().iterate((content: Uint8Array, key: string, n: number) => {
        decryptContact(localEncryptionKey, content).then((clearContent) => {
            let contact = new Contact(key);
            contact.parseFromUint8Array(key, clearContent);

            ret[n - 1] = contact;
        });
    }).catch(() => {
        throw new Error("db empty")
    });

    ret.sort(sortByCreationDateFn);

    return ret;
}

export function sortByCreationDateFn(c1: Contact, c2: Contact): number {
    if (c1.creationDate < c2.creationDate) {
        return -1;
    }
    if (c1.creationDate > c2.creationDate) {
        return 1;
    }
    return 0;
}