import React from 'react';

import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';


export type DeleteDialogProps = {
    lib: string;
    elem: any;
    onClose: (event: React.MouseEvent) => void;
    onConfirm: (event: React.MouseEvent, elem: any) => void;
}

export class DeleteDialog extends React.Component<DeleteDialogProps> {
    internalConfirmClick(event: React.MouseEvent) {
        this.props.onClose(event);
        this.props.onConfirm(event, this.props.elem);
    }

    render() {
        return (
            <Dialog
                open={true}
                onClose={this.props.onClose}
                aria-labelledby="delete-dialog-title"
                aria-describedby="delete-dialog-description"
            >
                <DialogTitle id="delete-dialog-title">{"Delete Confirmation"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="delete-dialog-description">
                        Are you sure to remove <span>{this.props.lib}</span>?
          </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.onClose.bind(this)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={this.internalConfirmClick.bind(this)} color="secondary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}