// import React from 'react';

import React from 'react';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Chip from '@material-ui/core/Chip';

const delayTyping = 300;

export type SearchComponentProps = {
    value: string;
    callBackUp: () => void;
    callBackDown: () => void;

    callBackSearch: (value: string) => void;

    timeItTook: number;
    position: number;
    totalLoadedContacts: number;

    online: boolean;
}

export class SearchComponentState {
    value: string;

    constructor() {
        this.value = "";
    }
}

export default class SearchComponent extends React.Component<SearchComponentProps, SearchComponentState> {
    // searchElem: React.Ref<HTMLInputElement>;
    searchKeyPressedTimerID: number = 0;

    constructor(input: SearchComponentProps) {
        super(input);
        // this.searchElem = React.createRef();
        this.state = {
            value: ""
        };
    }

    onChange(e: React.FormEvent) {
        const elem = e.currentTarget as HTMLInputElement;
        const newVal = elem.value;

        this.setState({ value: newVal })

        window.clearTimeout(this.searchKeyPressedTimerID);
        this.searchKeyPressedTimerID = window.setTimeout(() => {
            this.props.callBackSearch(newVal);
        }, delayTyping);
    }

    keyDownSearch(e: React.KeyboardEvent) {
        const elem = e.currentTarget as HTMLInputElement;
        const actualPosition = { start: elem.selectionStart as number, end: elem.selectionEnd as number };

        const resetCursor = () => {
            setTimeout(() => {
                elem.selectionStart = actualPosition.start;
                elem.selectionEnd = actualPosition.end;
            }, 1)
        };

        if (e.key === "ArrowUp") {
            this.props.callBackUp();
            resetCursor();
        } else if (e.key === "ArrowDown") {
            this.props.callBackDown();
            resetCursor();
        }
    }

    render() {
        let position = this.props.position + 1;
        if (this.props.totalLoadedContacts === 0) {
            position = 0
        }

        const info = position +
            "/" +
            this.props.totalLoadedContacts +
            " in " +
            this.props.timeItTook + "ms"

        return (
            <Paper className="search">
                <IconButton className="icons" aria-label="search">
                    <SearchIcon></SearchIcon>
                </IconButton>
                <InputBase
                    // ref={this.searchElem}
                    onKeyDown={this.keyDownSearch.bind(this)}
                    autoFocus={true}
                    className="input"
                    placeholder="What are you looking for?"
                    inputProps={{ 'aria-label': 'search for something' }}
                    onChange={this.onChange.bind(this)}
                />
                <Chip label={info} color="primary" />
                {!this.props.online && <Chip label="offline" color="secondary" />}
            </Paper>
        );
    }
}
